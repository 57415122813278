<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/setting") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-body.d-flex
          .list_detail
            img.list-image(:src="list_detail.image")
            .detail_title.mt-2.mb-3
              .title_text {{ list_detail.title }}
              i.fe-edit-2.edit-btn(@click="openEdit()")

            .detail-text.mb-1(v-if="list_detail.video_count") {{ list_detail.video_count.toLocaleString('en-US') }} 部影片
            .detail-text.mb-3(v-if="list_detail.last_update_at") 上次更新日 {{ list_detail.last_update_at.substring(0, 10) }}

            .form-group.mb-5
              .detail-text.mb-1 YT匯入連結
              textarea.form-control(v-model="list_detail.uri", rows="3")


            b-button.mr-2(variant="primary" size="lg" @click="updatePlayListDetail()")
              span 更新
            b-button(variant="danger" size="lg" @click="deletePlayListDetail()")
              i.fe-trash-2
              span  刪除播放清單 



          .list_video
            .form-group.mb-1.pl-0(style="width: 250px")
              multiselect(v-model="list_sortType", :options="options", select-label="", track-by="value", label="label")
            .video-scroll-list(v-if="list_video.length > 0")
              .video-item(v-for="(video, index) in list_video")
                .video-index {{ index + 1 }}
                .video-image  
                  img(:src="video.image")
                .video-text {{ video.title }}

  editPlayList(:show_status="show_status" :modal_data="list_detail" @sendForm="updatePlayListDetail")
              
    
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import editPlayList from "@/components/editPlayList"

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "直播清單設定",
      items: [
        {
          text: "直播管理",
          href: "/setting",
        },
        {
          text: "直播設定",
          href: '/setting',
        },
        {
          text: "直播清單設定",
          active: true,
        }
      ],

      list_id:'',
      list_detail:[],
      list_video:[],


      list_sortType: { label: "新增時間(新-舊)", value: 1 },

      options: [
        { label: "新增時間(新-舊)", value: 1 },
        { label: "新增時間(舊-新)", value: 2 },
        { label: "最熱門", value: 3 }
      ],

      show_status: false
    }
  },
  computed: {

  },
  created(){
    // 獲得操作公告ID
    this.list_id = this.$route.params.liveSettingId
  },
  mounted () {
    this.getPlayListDetail()
    this.getPlayListVideo()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.show_status = false
    })
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    editPlayList
  },
  watch: {
    list_sortType(_val){
      this.getPlayListVideo()
    }
  },
  methods: {
    openEdit(){
      this.show_status = true
    },
    // 獲得播放清單詳情
    getPlayListDetail () {
      let vm = this
      this.$root.apis.getPlayListDetail(this.list_id, function (_response) {
          vm.list_detail = _response.body.data
        }
      )
    },
    // 獲得播放清單影片列表
    getPlayListVideo () {
      let vm = this
      vm.list_video = []
      const item = {
        id: this.list_id,
        sort_type: this.list_sortType.value
      }
      this.$root.apis.getPlayListVideo(item,
        function (_response) {
          vm.list_video = _response.body.items
          vm.$forceUpdate()
        }
      )
    },


    // 更新播放清單列表
    updatePlayListDetail (_data) {
      let vm = this
      const item = {
        type: this.list_detail.type,
        title: this.list_detail.title,
        image: this.list_detail.image,
        youtube_playlist_uri:  this.list_detail.uri,
      }
      this.$root.apis.updatePlayListDetail(this.list_detail.id, _data ? _data : item, function () {
        vm.$root.common.showSingleNotify("更新成功")
        vm.show_status = false
        vm.getPlayListDetail()
        vm.getPlayListVideo()
      })
    },

    // 刪除播放清單列表
    deletePlayListDetail (_id) {
      let vm = this
      this.$root.common.confirmAction("刪除播放清單", "確定要刪除這個播放清單?", "刪除", "先不要",
        function(){
          vm.$root.apis.deletePlayListDetail(vm.list_id, function () {
            vm.$root.common.showSingleNotify("刪除成功")
            vm.$router.push({ path: '/setting', params: {} })
          })
        })
    },

    

   



  }
};
</script>


<style lang="sass" scoped>
::v-deep .card-body
  padding: 0
.list_detail
  min-width: 410px
  padding: 1.5rem
  border-right: 1px solid #ddd
  .list-image
    width: 100%
  .detail_title
    font-size: 22px
    color: #191919
    display: flex
    justify-content: space-between
    align-items: center
  .edit-btn
    cursor: pointer
  .detail-text
    color: #191919
    font-size: 15px

.list_video
  width: 100%
  padding: 1.5rem
  display: flex
  flex-direction: column
  .video-scroll-list
    overflow: auto
    max-height: 770px
    flex: 1
    .video-item
      border-bottom: 1px solid #ddd
      display: flex
      align-items: center
      padding: 1.5rem 0
      gap: 20px
      .video-image
        width: 152px
        img
          width: 152px
          height: 98px
          object-fit: cover
      .video-text
        color: #181818
</style>